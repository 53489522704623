import React,{useState} from "react";
import _heroRight from "../assets/banner2.jpg";
import TextTransition, { presets } from 'react-text-transition';
import heroRight from "../assets/hero_.jpg";
import section_one_Right from "../assets/banner_hero.jpg";
import section_x_Right from "../assets/procurement.jpeg"
import section_three_Right from "../assets/banner4.jpg";
import { NavLink, useNavigate } from "react-router-dom";

const LandingPage = ({setShowMobileNav}) => {

  const TEXTS = ['Security', 'Integrity', 'Reliability', 'Trust'];
  // const TEXTS = ['Business Name', 'Company Registration', 'Incorporated Trustee', 'Post Incorporation Services','Trade Mark','C Of O Registration','Scuml Registration','Money Lender’s License','NAFDAC Registration'];
  const HeroText = ["Regulatory compliance is crucial for maintaining legal and ethical business practices.","Our goal is to set the gold standard when it comes to compliance.","Registration and certifications with relevant regulatory authorities ensures your business minimize legal risks, and safeguards its reputation, ultimatelyy contributing to its long-term success and stability."]
  const sectionText = ["Let's make it simple: Government control means uniformity, regulation, fees, inspection, and yes, compliance.","Ensure compliance and be responsive to the feedback.","The Internet is a free and open platform. Everyone has the right to speak. However, compliance with the law is the bottom line that no one should violate."]
  const procurementText = [
    "Our process ensures quality, efficiency, and cost-effectiveness in every purchase.",
    "Bringing transparency and value to every procurement decision.",
    "With procurement made simple, focus more on what matters and less on the logistics."
  ];
  const [index, setIndex] = useState(0);
  const [heroIndex, setHeroindex] = useState(0);
  const [sectionIndex, setSectionindex] = useState(0);
  const navigate = useNavigate()

const [show,setShow] = useState(false)
  
  const services = [
    {
      title: "Business Name",
      description:"Business name registration provides legal protection for your brand, gives you credibility, promotes partnership and can help you secure financing such as government and commercial loans, grants, foreign aid and many more."    },
    {
      title: "Company Registration",
      description:"Company registration gives room for Investment from sales of shares. It gives your clients the confidence to deal with your organization. You can open Bank Accounts in your company name. It also provides corporate Legal Liability Protection."    },
    {
      title: "Incorporated Trustee",
      description:"Incorporated Trustees e.g Charity Foundations, NGO, Religious Organizations, Associations, Community Groups etc are non-business and non-profit making organizations formed to facilitate acquisition of corporate personality to achieve their objectives."
    },
    {
      title: "Post Incorporation Services",
      // description:"After registering your Business Name, Companies or Incorporated Trustees, you may discover that there is the need to change certain things either as a result of new circumstances, mistakes or regulatory mandate.          "
      description:"Post-incorporation adjustments, necessitated by changing circumstances or regulations, offer benefits such as safeguarding perpetual existence, enabling ownership transfer, building credit, and facilitating changes in various aspects.  "
    },
    {
      title: "Trade Mark",
      description:"By Registering the trademark, a business can distinguish its product and service from its rivals. It helps to make a unique identity in the consumer's mind by using the mark consistently over time, which promotes brand reputation and loyalty."
    },
    {
      title: "C Of O Registration",
      description:"A Certificate of Occupancy is a legal document issued by the government that proves that a person owns land in Nigeria. The Government reserves the right to seize any plot of land or property without a C of O at any time without any compensation paid. "
    }, 
    {
      title: "Scuml Registration",
      description:"The SCUML certificate, issued by the EFCC, serves as evidence that an organization's bank account is not involved in money laundering activities, providing assurance and compliance with anti-money laundering regulations."
    }, 
    {
      title: "Money Lender’s License",
      description:"Being a licensed moneylender grants the right to take legal action for recovering loans. This ensures the flow of money, supporting business growth, investment, and job creation. In Nigeria, obtaining this license is mandatory. "
    }, 
    {
      title: "NAFDAC Registration",
      description:"NAFDAC ensures product quality and safety for public use. Registering your products demonstrates commitment to compliance, consumer safety, and builds trust, as it signifies adherence to regulatory standards and guidelines.   "
    }, 
     {
      title: "NewsPapper Publication",
      description:"A newspaper is a publication consisting of a number of large sheets of folded paper, on which news, advertisements, and other information is published."
    },
  ];

  React.useEffect(() => {
    const intervalId = setInterval(
      () => {
        setIndex((index) => index + 1)
        setHeroindex((index) => index + 1)
        setSectionindex((index) => index + 1)
      },
      5000, // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div onClick={()=>setShowMobileNav(false)}>
      
      <section
        className="nyd-hero nyd-hero--home bg--secondary"
        style={{ marginTop: 70 }}
      >
        <div className="nyd-page-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6" >
                <h3 className="nyd-h2 text-is--medium text-is--white">
                Ensuring Regulatory<br /> Compliance
                 <span className="text-is--dark">
                    {" "}
                    <br/>
                    Protecting Your Business
                    <br />
                 <p style={{margin:0,display:"flex"}}> with  <TextTransition style={{marginLeft:"2%"}} springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition></p>
                  </span>      

                </h3>
                <p className="nyd-body text-is--regular text-is--white">
                <TextTransition springConfig={presets.wobbly}>{HeroText[heroIndex % HeroText.length]}</TextTransition>
                </p>
                <div className="mt-5">
                  <NavLink
                    to={"/signup"}
                    className="nyd-button nyd-button--primary"
                  >
                    Get Started
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <p>images goes here</p> */}
                <img
                  src={_heroRight}
                  alt="Hero"
                  className="nyd-hero__image slide-in-from-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="nyd-section bg--white py-5">
        <div className="nyd-page-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6"  data-aos="fade-up">
                <h1 className="nyd-h2 text-is--medium text-is--dark">
                We bring 
                  <span className="text-is--secondary">
                  <span style={{marginLeft:15}}>you</span> 
                    <br />
                    compliance with ease.
                  </span>
                </h1>
                <p className="nyd-body text-is--light text-is--dark">
                <TextTransition springConfig={presets.wobbly}>{sectionText[sectionIndex % sectionText.length]}</TextTransition>

                </p>
                <div className="mt-5">
                  <NavLink
                    to="/about-us"
                    className="nyd-button nyd-button--small nyd-button--primary-outline"
                  >
                    Learn More
                    {/* <div className="mx-1">
                      <i className="bi bi-arrow-up-right"></i>
                    </div> */}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-right">
                <img
                  src={section_one_Right}
                  alt="Lady with Flag"
                  className="nyd-section__image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nyd-section bg--secondary py-5" id="services">
        <div className="nyd-page-wrapper">
          <div className="container-fluid">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <p className="text-is--dark"># WHY CHOOSE US </p>
                <p className="text-is--dark">

                Our team of highly trained and experienced professionals <br/>and agents work diligently and tirelessly to ensure that you achieve regulatory compliance with EASE.     
                </p>
              </div>
            </div>

            <div className="row align-items-center col-xl-12 col-md-7"  data-aos="zoom-in-up">
              {/* <div className="col-xl-4 my-3"> */}
              {services.map((item, index) => (
                <div  key={index} className={`col-xl-4 my-3 ${index + 1 < 7 ? 'show_' : (show && index + 1 > 6) ? 'show_' : 'hide_' }` }>
                  <div className="card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                      }}
                    >
                      <img
                        src={heroRight}
                        className=""
                        alt="Card"
                        width={40}
                        height={40}
                        style={{ borderRadius: 100 }}
                      />
                    </div>
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        {item.title}
                      </h5>
                      <p
                        className="card-text"
                        style={{ color: "#57595A", fontSize: "12px" }}
                      >
                        {item.description}
                      </p>
                      <NavLink
                        to={"/login"}
                        className="nyd-button nyd-button--primary my-btn"
                        style={{ fontSize: 12 }}
                      >
                        Proceed
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
              {/* </div> */}

              <div className="col-xl-3 d-flex justify-content-start justify-content-xl-start my-3" onClick={()=>navigate("/services")}>
              {/* <div className="col-xl-3 d-flex justify-content-start justify-content-xl-start my-3" onClick={()=>setShow(!show)}> */}
                <button
                  className="nyd-button nyd-button--small  nyd-button--dark-outline"
                >
                  {show ? "See less" : "See more"} {">"}  
                  {/* <div className="mx-1">
                    <i className="bi bi-arrow-up-right"></i>
                  </div> */}
                </button>
              </div>
            </div>
            <div className="leadership-carousel mt-5">
              <div className="col-lg-11 mx-auto">
                {/* <div className="d-none d-lg-block">
                  <button className="leadership-carousel__button leadership-carousel__button-prev">
                    <i className="bi bi-chevron-left"></i>
                  </button>
                  <button className="leadership-carousel__button leadership-carousel__button-next">
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div> */}
                <div data-ssrc-id="-2uo0s4" className="ssr-carousel">
                  {/* Style section goes here */}
                  <div className="ssr-carousel-slides">
                    {/* Leadership Cards go here */}
                  </div>
                
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="nyd-section bg--white py-5">
        <div className="nyd-page-wrapper"  data-aos="fade-up">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6" >
                <h1 className="nyd-h2 text-is--medium text-is--dark">
                We bring 
                  <span className="text-is--secondary">
                  <span style={{marginLeft:15}}>you</span> 
                    <br />
                    streamlined procurement.
                  </span>
                </h1>
                <p className="nyd-body text-is--light text-is--dark">
                <TextTransition springConfig={presets.wobbly}>{procurementText[sectionIndex % procurementText.length]}</TextTransition>

                </p>
                <div className="mt-5">
                  <NavLink
                    to="/easy-procurement"
                    className="nyd-button nyd-button--small nyd-button--primary-outline"
                  >
                    Learn More
                    {/* <div className="mx-1">
                      <i className="bi bi-arrow-up-right"></i>
                    </div> */}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-6">
              {/* <div className="col-lg-6" data-aos="fade-right"> */}
                <img
                  src={section_x_Right}
                  alt="Lady with Flag"
                  className="nyd-section__image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nyd-section bg--secondary py-5">
        <div className="nyd-page-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
            <div className="col-lg-6" >
                <img
                  src={section_three_Right}
                  alt="Lady with Flag"
                  className="nyd-section__image"
                />
              </div>
              <div className="col-lg-6"  data-aos="fade-up">
              {/* <div className="col-lg-10 p-3 mx-auto"> */}
            <div className="nyd-cta">
              <p className="text-is--dark"># Need Help? </p>
              <div className="col-lg-6 mx-auto text-center pt-3">
                <h3 className="nyd-h6 text-is--dark text-is--semibold">
                  We are proud to have 24/7 customer support
                </h3>
              </div>
            
              <div className="mt-5 text-center" >
                <NavLink
                 to={"/contact-us"}
                 style={{border:'1px solid white !important'}}
                  className="nyd-button nyd-button--small nyd-button--primary-outline"
                >
                  Contact us
                  <div className="mx-1">
                    {/* <i className="bi bi-arrow-up-right"></i> */}
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  );
};

export default LandingPage;
